@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Sansation';
  src: url('./assets/fonts/Sansation_Regular.ttf');
  font-weight: normal;
}

@font-face {
  font-family: 'Sansation';
  src: url('./assets/fonts/Sansation_Bold.ttf');
  font-weight: bold;
}

@font-face {
  font-family: 'Sansation';
  src: url('./assets/fonts/Sansation_Light.ttf');
  font-weight: 300;
}

.top-bar {
  clip-path: polygon(0 0, 100% 0, 100% 40%, 12% 100%, 0 23%);
}

@media screen and (min-width: 640px) {
  .top-bar {
    clip-path: none;
  }
}

.bottom-bar {
  clip-path: polygon(0 60%, 88% 0, 100% 77%, 100% 100%, 0 100%);
}

.no-shadow {
  box-shadow: none !important;
}

.content h1 {
  font-size: 2rem;
}

.content h2 {
  font-size: 1.3rem;
}

.content h3 {
  font-size: 1.2rem;
}

.content h4 {
  font-size: 1.15rem;
  font-weight: 600;
}

.content h5 {
  font-size: 1.1rem;
  font-weight: 600;
}

.content h6 {
  font-size: 1rem;
  font-weight: 600;
}
